const antdThemeConfig = {
  token: {
    colorPrimary: '#4373a3',
    screenXS: 0,
    screenXSMax: 374,
    screenXSMin: 0,
    screenSM: 375,
    screenSMMax: 809,
    screenSMMin: 375,
    screenMD: 810,
    screenMDMax: 1079,
    screenMDMin: 810,
    screenLG: 1080,
    screenLGMax: 1279,
    screenLGMin: 1080,
    screenXL: 1280,
    screenXLMax: 1439,
    screenXLMin: 1280,
    screenXXL: 1440,
    screenXXLMin: 1440,
  },
  components: {
    Switch: {
      colorPrimary: '#14b8a6',
      colorPrimaryHover: '#0d9488',
      colorTextQuaternary: '#ff4d4f',
      colorTextTertiary: '#e11d48',
    },
  },
}

export default antdThemeConfig
